<template>
    <div id="apps">
        <div class="myjk clear">
            <div class="tophead">
                <p>{{$store.getters.userinfo.nickName}}</p>
                <div class="souiy"><img :src="$store.getters.userinfo.avatar"/>
                    <div class="clear">
                        <div class="left">
                            <h4>组建团队收益</h4>
                            <h6>￥{{amount}}元</h6>
                        </div>
                        <div class="left">
                            <h4>当前团队成员总数</h4>
                            <h6>{{memberCount}}个</h6>
                        </div>
                    </div>
                </div>
            </div>
            <h1 class="hhy">
                <router-link to="/commission-protocol">
                    级别佣金与团队说明
                </router-link>
            </h1>
            <table class="tab">
                <thead>
                <tr>
                    <th v-for="( item,i ) in dataH">{{item}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in list">
                    <td>{{item.name}}</td>
                    <td>{{item.contributionAmount}}</td>
                    <td>{{item.refundAmount}}</td>
                    <td>{{item.allAmount}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
  import {getUserMyTeamInfo} from "@/api/user";

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        dataH:['团长姓名','自购或推荐为您带来的收益','退款后您减少的收益',' 为您带来的总收益'],
        amount: 0,
        list: [],
        memberCount: 0
      }
    },
    methods: {
      loadInfo() {
        getUserMyTeamInfo({}).then(data => {
          if (data.success == true) {
            this.amount = data.data.amount;
            this.list = data.data.list;
            this.memberCount = data.data.memberCount;
          }
        })
      }
    },
    created() {
      this.loadInfo()
    }
  }
</script>

<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    body {
        background-color: rgba(248, 248, 248, 100);
    }

    .myjk .tophead {
        width: 100%;
        padding-top: 15%;
        background-color: rgba(218, 218, 218, 100);
        padding-bottom: 6%;
    }

    .myjk .tophead img {
        width: 18%;
        position: absolute;
        top: -50%;
        left: 0;
        border: solid 3px #fff;
        border-radius: 100%;
        display: inline-block;
        float: left;
    }

    .myjk .tophead p {
        text-indent: 25%;
        color: #333;
        font-size: 15px;
        display: block;
        font-weight: 500;
    }

    .myjk .tophead .souiy {
        background: #fff;
        border-radius: 6px;
        width: 90%;
        margin: 1.4% auto -10% auto;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
        position: relative;
    }

    .myjk .tophead .souiy .left {
        width: 40%;
        margin-left: 20%;
        float: left;
        text-indent: 5%
    }

    .myjk .tophead .souiy div.left:nth-child(2) {
        margin-left: 0;
    }

    .myjk .tab {
        margin-top: 5%;
    }

    .myjk .tophead .souiy h4 {
        font-weight: 400;
        font-size: 13px;
        color: #B6B6B6;
        margin-top: 12%;
    }

    .myjk .tophead .souiy h6 {
        font-weight: 400;
        font-size: 12px;
        color: rgb(227, 0, 0);
        margin: 3% 0 9% 0;
    }

    .myjk .tab th {
        line-height: 18px;
        background-color: #E8E8E8;
        width: 25%;
        color: rgba(30, 29, 29, 100);
        font-size: 12px;
        font-weight: 400;
        padding: 2% 3%;
    }

    .myjk .tab table {
        border: solid 1px #DEDEDE;
    }

    .myjk .hhy {
        color: rgba(210, 8, 8, 100);
        display: block;
        margin-top: 10%;
        font-size: 12px;
        font-weight: 400;
        width: 97%;
        padding-right: 3%;
        padding-bottom: 1.5%;
        border-bottom: 1px solid rgba(187, 187, 187, 100);
        text-align: right;
        font-family: '方正准圆-标准';
    }

    table {
        border-collapse: collapse;
    }

    table, th, td {
        border: 1px solid #E0E0E0;
    }

    .myjk .tab td {
        color: rgba(30, 29, 29, 100);
        font-size: 12px;
        background: #fff;
        text-align: center;
        line-height: 30px;
        font-family: 'PingFang SC';
    }
</style>
